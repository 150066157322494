// Variables

$alertVerticalPadding: 10px;
$alertHorizontalPadding: 20px;
$alertLineHieght: 20px;

$alertWindowDistance: 14px;
$alertFadeOutDuration: .5s;
$alertVisibleDuration: 5s;

$alertInfoBackground: #106BF4; //Blue-600
$alertSuccessBackground: #009868; //Green-600
$alertErrorBackground: #e02424; //Red-600

// Styles
.alert {
  position: fixed;
  top: $alertWindowDistance;
  right: $alertWindowDistance;
  padding: $alertVerticalPadding $alertHorizontalPadding;
  border-radius: 4px;
  box-shadow: 0px 4px 20px rgb(0 0 0 / 28%);
  color: white;
  line-height: $alertLineHieght;
  animation: alert-fade-out $alertFadeOutDuration 1;
  animation-fill-mode: forwards;
  animation-delay: $alertVisibleDuration;
  z-index: 2147483647;
  &.alert-info {
    background-color: $alertInfoBackground;
  }

  &.alert-error {
    background-color: $alertErrorBackground;
  }

  &.alert-success {
    background-color: $alertSuccessBackground;
  }
}

@keyframes alert-fade-out {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    transform: translateY(-(2 * $alertVerticalPadding + $alertLineHieght + $alertWindowDistance));
  }
}
