.containerWrapper {
  min-width: 320px;
  height: 100%
}

.innerContent {
  word-wrap: break-word;
}

.wideContainer {
  max-width: 1170px;
  width: 100%;
}

.midWideContainer {
  max-width: 960px;
  width: 100%;
}

.midContainer {
  max-width: 720px;
  width: 100%;
}

.smallContainer {
  max-width: 550px;
}

.fullContainer {
  width: 100%;
}

/* Legacy for RowContainer/V1 content */
.fullContainer .containerInner {
  width: 1170px;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.fullContainer .containerInnerV2 {
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.fullContainer .containerInnerV2 .containerInnerV2 {
  max-width: inherit
}

.bgW100 {
  background-size: 100% auto !important;
  -webkit-background-size: 100% auto !important;
  background-repeat: no-repeat !important;
}

.bgW100H100 {
  background-size: 100% 100% !important;
  -webkit-background-size: 100% 100% !important;
  background-repeat: no-repeat !important;
}
.bgNoRepeat {
  background-repeat: no-repeat !important;
}

.bgRepeatX {
  background-repeat: repeat-x !important;
}

.bgRepeatY {
  background-repeat: repeat-y !important;
}

.bgCoverV2 {
  background-size: cover !important;
  -webkit-background-size: cover !important;
  background-attachment: fixed !important;
  background-repeat: repeat repeat !important;
  background-position: center center !important;
}

.bgCoverCenter {
  background-size: cover !important;
  -webkit-background-size: cover !important;
  background-repeat: repeat repeat !important;
  background-position: center center !important;
}
