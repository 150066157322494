/* removes user-agent stylesheet */

*,
*:focus {
  outline: none;
}

[data-show-only='none'],
[data-show-only='none'][data-page-element="FlexContainer/V1"],
[data-show-only='none'][data-page-element="RowContainer/V1"]
 {
  display: none
}

.hide {
  display: none;
}
.forceHide {
  display: none !important;
}

/*
  This is important to add otherwise even Shadow DOM would inherit
  some styles from html and body tags
  https://lamplightdev.com/blog/2019/03/26/why-is-my-web-component-inheriting-styles/
  Below are some styles covering defaults from bootstrap initially has
*/


/* 
Setting  this otherwise when pulling style guide it could affect alot
existing elements in the page (as the style guide sets a font-size to html
whic overrides the html below)
*/
.elPageContent {
  font-size: 14px;
}

:host, body {
  color: inherit !important;
  -webkit-font-smoothing: antialiased;
}

html, :host {
  all: initial;
  font-family: sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  color: #2f2f2f;
  background-color: #fff;
}


u { 
  text-decoration: underline;
}

strike {
  text-decoration: line-through;
} 

a {
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}

body {
  line-height: 1.42857143;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 500;
  line-height: 1.1;
}

/*
  Reset CSS
  source: https://meyerweb.com/eric/tools/css/reset/
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0 auto;
	padding: 0;
	border: 0;
	/* font-size: 100%; COMMENTED FROM ORIGINAL */
	vertical-align: baseline;
}

span {
  margin: 0
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
/* body {
	line-height: 1; COMMENTED FROM ORIGINAL
} */
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

@keyframes store-search-slide-in {
  0% {
    transform: translateY(-100%)
  }
}