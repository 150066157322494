.mfp-bg {
  opacity: 0.85;
  filter: alpha(opacity=85);
}

.mfp-zoom-out-cur,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close,
.mfp-zoom {
  cursor: inherit;
}
