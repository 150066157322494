@use '../page-content/raw_html_content.scss';

@use './general.css';
@use './modal.css';

@use './block_settings.css';
@use './magnific-popup-custom.css';
@use './bootstrap-grid.min.css';
@use './responsive.scss';

@use '../../../editor/app/javascript/styles/layers.css';


.elContentWrapper .elContent {
  @include raw_html_content.raw-html-content;
}

// Support for google autocomplete in modals
.pac-container {
  z-index: 100002;
}
