
@mixin raw-html-content {
  text-align: left;

  h1,h2,h3,h4,h5 { font-weight: 600;}

  h2 {
    font-size: 1.5em;
    line-height: 2em;
  }
  
  h3 {
    font-size: 1.25em;
    line-height: 1.75em; 
  }
  
  h4 { 
    font-size: 1.125em;
    line-height: 1.75em;
  }

  a {
    color: rgba(37, 99, 235, 1);
    font-weight: 400;
    text-decoration: underline;
  }

  ul > li {
    margin-left: 1.25em;
    list-style-type: disc;

    ul > li {
      list-style-type: square;

      ul > li {
        list-style-type: circle;

      }
    }
  }

  ol > li {
    margin-left: 1.25em;
    list-style-type: decimal;

    ol > li {
      list-style-type: upper-roman;
    }
  }

  blockquote {
    display: block; 
    margin-bottom: 1em; 
    overflow: hidden;
    padding-right: 1.5em;
    padding-left: 1.5em;
    font-style: italic;
    border-left: 5px solid #ccc;
  }
}
